import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute }     from '@angular/router';
import { CardService }        from '@omnipas/services/card.service';
import { DataService }        from '@omnilib/services/data.service';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { ListService }        from '@omnilib/services/list.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { MessageService }     from '@omnipas/services/message.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { PaymentService }     from '@omnipas/services/payment.service';
import { RouterService }      from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit, OnDestroy {

  initialized: boolean = false;
  result:      string  = '';
  showLogout:  boolean = false;

  @ViewChild('ingenicoForm') ingenicoForm : ElementRef;

  constructor ( public route:       ActivatedRoute
              , public card:        CardService
              , public data:        DataService
              , public environment: EnvironmentService
              , public list:        ListService
              , public loading:     LoadingService
              , public message:     MessageService
              , public pageheader:  PageHeaderService
              , public payment:     PaymentService
              , public router:      RouterService
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    this.initialized = false;
    try {
      await this.initialize();
    } catch(e) {
      this.message.setErrorMessage( 'payment.fail' );
      this.showLogout = false;
    }
    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  async initialize() {
    this.router.paying = true;

    this.pageheader.setPageHeader( 'pagetitle.payment', 'pagetext.payment' );

    const params      = this.route.params;
    const queryparams = this.route.queryParams;
    const ingenico    = this.environment.getEnvValue('ingenico');

    if ( params['value']['token1'] ) {

      this.result = await decodeURIComponent(params['value']['token1']);

      if ( queryparams['value'] ) {
        let checksign    = await this.payment.checkSignOut( queryparams['value'], this.list.getValue( ingenico, 'sha_out_key' ) );
        let orderID      = queryparams['value']['orderID'];
        let jwt          = queryparams['value']['JWT'];
        let cardholderid = orderID.substring(orderID.indexOf('&_&') + 3);

        await this.loading.load( queryparams['value']['JWT'] );

        if ( checksign && this.list.getValue ( this.router.persondata, 'cardholderID' ) == cardholderid ) {
          try {
            await this.payment.updatecardholdernote ( this.result + ' payment' );
          } catch(e) {
            this.message.setErrorMessage( 'payment.fail' );
            this.showLogout = true;
          }

          switch ( this.result ) {
            case 'success' :
              if ( await this.card.create() ) {
                this.message.setSuccessMessage ( 'payment.success');
              } else {
                this.message.setErrorMessage( 'payment.fail' );
              }
              this.showLogout = true;
              break;
            case 'cancel' :
              this.message.setErrorMessage( 'payment.cancel' );
              this.showLogout = true;
              break;
            case 'decline' :
              this.message.setErrorMessage( 'payment.decline' );
              this.showLogout = true;
              break;
            case  'fail' :
            default :
              this.message.setErrorMessage( 'payment.fail' );
              this.showLogout = true;
              break;
          }
        } else {
          this.message.setErrorMessage( 'payment.fail' );
          this.showLogout = true;
        }
      }
    } else {
      if ( this.list.emptyList( this.router.persondata ) ) {
        this.router.logout();
      } else  if ( this.list.emptyList ( ingenico ) || this.list.emptyList ( this.environment.getEnvValue('cardprices') ) ) {
        this.message.setErrorMessage( 'payment.config' );
        this.showLogout = true;
      } else {
        await this.payment.initialize();
        this.result = 'start';
        this.showLogout = false;
      }
    }
  }

  async submit() {
    await this.payment.updatecardholdernote ( 'start payment' );
    let form = this.ingenicoForm.nativeElement;
    form.submit();
  }

  cancel() {
    this.message.setErrorMessage( 'payment.cancel' );
  }

  logout() {
    this.router.paying = false;
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }

}
