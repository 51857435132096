import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { RouterService }      from '@omnipas/services/router.service';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'omnipas-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {
  version: string = '';
  hash:    string = '';
  date:    string = '';
  //info            = versionInfo;

  constructor ( public environment: EnvironmentService
              , public router:      RouterService
              ) {}

  ngOnInit() {
  }

  showAbout() {
    this.router.showabout = true;
  }

  ngOnDestroy() {
    return;
  }

}
