import { Injectable } from '@angular/core';
import { DataService }       from '@omnilib/services/data.service';
import { ListService }       from '@omnilib/services/list.service';
import { PersonService }     from '@omnipas/services/person.service';
import { RouterService }     from '@omnipas/services/router.service';
import { environment }       from '@omnienv/omnipas/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor( public data:           DataService
             , public list:           ListService
             , public person:         PersonService
             , public router:         RouterService
             ) {
  }

  async update( status, remarks = '' ) {
    if ( status != '' && !this.list.emptyList( this.router.persondata ) ) {
      let params = { 'statusId' : status };
      let result = await this.data.callapi ( 'post', 'card/update', params );

      if ( result['result']['code'] && result['result']['code'] == '0' ) {
        if ( await this.person.getPersonData() ) {
          return true;
        }
      }
    }
    return false;
  }

  async create() {
    if ( !this.list.emptyList( this.router.persondata ) ) {
      let result = await this.data.callapi ( 'put', 'card/create', {} );

      if ( result['result']['code'] && result['result']['code'] == '0' ) {
        if ( await this.person.getPersonData() ) {
          return true;
        }
      }
    }
    return false;
  }

  async block() {

    let dbtype = environment['dbtype'] ?? '';

    if ( dbtype == "2" ) {
      if ( !this.list.emptyList( this.router.persondata ) ) {
        let result = await this.data.callapi ( 'post', 'card/block', {} );

        if ( result['result']['code'] && result['result']['code'] == '0' ) {
          if ( await this.person.getPersonData() ) {
            return true;
          }
        }
      }
    } else {
      return this.update(6);
    }
    return false;
  }

  async unblock() {
    let dbtype = environment['dbtype'] ?? '';

    if ( dbtype == "2" ) {
      if ( !this.list.emptyList( this.router.persondata ) ) {
        let result = await this.data.callapi ( 'post', 'card/unblock', {} );

        if ( result['result']['code'] && result['result']['code'] == '0' ) {
          if ( await this.person.getPersonData() ) {
            return true;
          }
        }
      }
    } else {
      return this.update(5);
    }
    return false;
  }

}
