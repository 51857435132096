import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardService }        from '@omnipas/services/card.service';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { ListService }        from '@omnilib/services/list.service';
import { MessageService }     from '@omnipas/services/message.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { RouterService }      from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-blockcard',
  templateUrl: './blockcard.component.html'
})
export class BlockCardComponent implements OnInit, OnDestroy {

  initialized:    boolean = false;
  showLogout:     boolean = false;
  showRequestNew: boolean = false;

  constructor ( public card:        CardService
              , public environment: EnvironmentService
              , public list:        ListService
              , public message:     MessageService
              , public pageheader:  PageHeaderService
              , public router:      RouterService
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader ( 'pagetitle.blockcard', 'pagetext.blockcard' );
    }

    this.showRequestNew =  this.environment.getEnvValue('cardrequest')
                        && (    this.list.getValue( this.router.persondata, 'photoValidation', true, true ) == 'False'
                             || this.list.getValue( this.router.persondata, 'photoApproved', true, true ) == 'True'
                           );

    this.initialized = true;

    this.router.toggleshowwait( false );
  }

  async blockcard() {

    this.router.toggleshowwait( true );

    if ( await this.card.block() ) {
      this.message.setSuccessMessage( 'block.success' );
    } else {
      this.message.setErrorMessage( 'block.failure' );
    }

    if ( !this.showRequestNew ) {
      this.setLogout();
    }

    this.router.toggleshowwait( false );

  }

  requestnew() {
    this.router.goTo('requestcard', true);
  }

  cancel() {
    this.router.backTo('welcome');
  }

  setLogout() {
    this.showLogout = true
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }
}
