<div *ngIf="initialized && !router.loggingin" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div *ngIf="!message.error" class="w3-margin">
    <input
      type        = "button"
      id          = "login"
      name        = "login"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      title       = "{{ 'buttons.surfconext' | translate: router.transparams }}"
      value       = "{{ 'buttons.surfconext' | translate: router.transparams }}"
      (click)     = "submit()"
    >
  </div>
  <div class="w3-margin">&nbsp;</div>
  <div *ngIf="router.transparams.customer_shortname == 'HVA' || router.transparams.customer_shortname == 'UVA'" class="w3-margin">
    <p>{{ 'login.moreinfo' | translate: router.transparams }}<br/>
      <b>{{ 'login.students' | translate: router.transparams }}:</b>&nbsp;<a target="_blank" href="{{ 'login.studenturl' | translate: router.transparams }}">{{ 'login.clickhere' | translate: router.transparams }}</a><br/>
      <b>{{ 'login.employees' | translate: router.transparams }}:</b>&nbsp;<a target="_blank" href="{{ 'login.employeeurl' | translate: router.transparams }}">{{ 'login.clickhere' | translate: router.transparams }}</a><br/>
    </p>
  </div>
  <div *ngIf="environment.getEnvValue('talland')" class="w3-container w3-margin">
    <span>Problemen met inloggen?</span><br/>
    <span>&nbsp;&nbsp;Voor studenten: stel je vraag aan <a class="link" href="mailto:studentenhelpdesk&#64;tallandcollege.nl">studentenhelpdesk&#64;tallandcollege.nl</a></span><br/>
    <span>&nbsp;&nbsp;Voor medewerkers: stel je vraag aan <a class="link" href="mailto:servicepunt&#64;tallandcollege.nl">servicepunt&#64;tallandcollege.nl</a></span><br/>
  </div>
</div>
