<div *ngIf="initialized" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div class="w3-margin w3-center" [class]="phototaken ? 'hide' : 'show'">
    <video #camera id="camera" autoplay class="w3-image w3-mobile">
    </video>
  </div>

  <div class="w3-margin" [class]="phototaken ? 'hide' : 'show'">
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile w3-margin-right pageButton w3-round"
      name        = "back"
      value       = "{{ 'buttons.back' | translate: router.transparams }}"
      title       = "{{ 'buttons.back' | translate: router.transparams }}"
      (click)     = "back()"
    >
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "takePicture"
      value       = "{{ 'buttons.photo' | translate: router.transparams }}"
      title       = "{{ 'buttons.photo' | translate: router.transparams }}"
      (click)     = "takePicture()"
      [disabled]  = "message.error ? 'disabled' : ''"
    >
  </div>

  <div class="w3-margin w3-center" [class]="!phototaken ? 'hide' : 'show'">
    <p class="w3-left-align">{{ 'pagetext.webcamnote' | translate: router.transparams }}</p>
    <canvas #myCanvas class="w3-image w3-mobile"></canvas>
  </div>

  <div class="w3-margin" [class]="!phototaken ? 'hide' : 'show'">
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile w3-margin-right pageButton w3-round"
      name        = "reset"
      value       = "{{ 'buttons.retry' | translate: router.transparams }}"
      title       = "{{ 'buttons.retry' | translate: router.transparams }}"
      (click)     = "reset()"
    >
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "save"
      value       = "{{ 'buttons.next' | translate: router.transparams }}"
      title       = "{{ 'buttons.next' | translate: router.transparams }}"
      (click)     = "save()"
      [disabled]  = "message.error ? 'disabled' : ''"
    >
  </div>
</div>
