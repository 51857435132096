<div class="w3-center">
  <div>
    <h2>ONDERHOUD - MAINTENANCE</h2>
  </div>
  <br/>
  <div>
    <p>Momenteel worden er onderhoudswerkzaamheden verricht aan deze website. Onze excuses voor het ongemak<span (click)="hideMaintainance()">.</span></p>
    <p><i>Currently, maintenance is being performed on this website. We apologize for the inconvenience.</i></p>
  </div>
</div>
