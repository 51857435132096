import { Component, OnInit, ViewChild, Renderer2, ElementRef, OnDestroy, OnChanges, EventEmitter, HostListener } from '@angular/core';
import { DateService }        from '@omnipas/services/date.service';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { ListService }        from '@omnilib/services/list.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { PhotoService }       from '@omnipas/services/photo.service';
import { RouterService }      from '@omnipas/services/router.service';

import heic2any from 'heic2any';

@Component({
  selector: 'omnipas-welcome',
  templateUrl: './welcome.component.html'
})
export class WelcomeComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('name')                 public name:                 ElementRef;

  firstName: string  = '';
  fileinput: string  = '';
  expdate:   string  = '';
  what:      string = '';
  warning:   string = '';

  initialized:            boolean = false;
  showAgreed:             boolean = false;
  showFirstPhoto:         boolean = false;
  showCardActivate:       boolean = false;
  showCardInfo:           boolean = false;
  showCardPreview:        boolean = false;
  showCardRequestStatus:  boolean = false;
  showCardBlock:          boolean = false;
  showCardDeblock:        boolean = false;
  showCardRequest:        boolean = false;
  personFound:            boolean = false;
  cardholderFound:        boolean = false;
  photovalidation:        boolean = false;
  photorequired:          boolean = false;
  photoapproved:          boolean = false;
  showPhotoButtons:       boolean = true;
  showPhotoDisapproved:   boolean = false;
  showPhotoUnapproved:    boolean = false;
  showChangeAddress:      boolean = false;

  constructor ( public date:        DateService
              , public environment: EnvironmentService
              , public list:        ListService
              , public loading:     LoadingService
              , public pageheader:  PageHeaderService
              , public photo:       PhotoService
              , public router:      RouterService
              , public renderer:    Renderer2
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    await this.initialize();
    this.router.toggleshowwait( false );
  }

  async initialize() {
    if ( this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      this.initialized           = false;
      this.showFirstPhoto        = false;
      this.showCardRequestStatus = false;
      this.showCardBlock         = false;
      this.showCardRequest       = false;
      this.showChangeAddress     = false;
      //this.firstName = this.list.getValue( this.session.cardholderData, 'firstName', true );
      this.pageheader.setPageHeader( 'pagetitle.welcome', 'pagetext.welcome' );

      this.showFirstPhoto  = this.list.getValue( this.router.persondata, 'photo', true ) == '';
      this.showCardPreview = +this.list.getValue( this.router.persondata, 'cardStatus', true ) > 0;
      this.showCardInfo    = +this.list.getValue( this.router.persondata, 'cardStatus', true ) > 0;

      if ( this.list.getValue( this.router.persondata, 'cardID', true ) == '' ) {
        this.router.persondata['cardStatus'] = 0;
      }

      this.photovalidation      = this.list.getValue( this.router.persondata, 'photoValidation', true, true ) == 'True';
      this.photorequired        = this.list.getValue( this.router.persondata, 'photoRequired', true, true )   == 'True';
      this.photoapproved        = this.list.getValue( this.router.persondata, 'photoApproved', true, true )   == 'True';

      let canrequest: boolean = false;

      if ( this.photorequired ) {
        if ( this.photovalidation ) {
          if ( this.photoapproved ) {
            canrequest = true;
          }
        } else {
          canrequest = true;
        }
      } else {
        canrequest = true;
      }

      this.showPhotoDisapproved = this.photovalidation && this.list.getValue( this.router.persondata, 'photoApproved', true, true ) == 'False';
      this.showPhotoUnapproved  = this.photovalidation && this.list.getValue( this.router.persondata, 'photoApproved', true, true ) == '' && this.list.getValue( this.router.persondata, 'photo', true ) != '';

      switch ( +this.list.getValue( this.router.persondata, 'cardStatus', true ) ) {
        case 0:
          if ( (!this.showFirstPhoto) && ( this.list.getValue( this.router.persondata, 'cardholderProfile', true ).toLowerCase() == 'student' )) {
            this.showChangeAddress = true;
          }
          break;
        case 1:
          if ( (!this.showFirstPhoto) && ( this.list.getValue( this.router.persondata, 'cardholderProfile', true ).toLowerCase() == 'student' )) {
            this.showChangeAddress = true;
          }

          if ( this.list.getValue( this.router.persondata, 'photoValidation', true, true ) == 'True' ) {
            this.showPhotoButtons = false;
          }
          break;
        case 2:
          if ( this.list.getValue( this.router.persondata, 'photoValidation', true, true ) == 'True' ) {
            this.showPhotoButtons = false;
          }
          break;
        case 3:
          this.showCardActivate = true;
          break;
        case 4:
          this.showCardRequest = canrequest;
          break;
        case 5:
          this.showCardBlock = true;
          break;
        case 6:
          this.showCardRequest = canrequest;
          this.showCardDeblock = true;
          break;
        default:
          break;
      }
    }
    this.initialized = true;
  }

  hideWarning() {
    document.getElementById('showwarning').classList.remove("show");
    document.getElementById('showwarning').classList.add("hide");
  }

  showWarning( $warning ) {
    this.warning = $warning;
    document.getElementById('showwarning').classList.remove("hide");
    document.getElementById('showwarning').classList.add("show");
  }

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  doAction() {
    switch ( this.what ) {
      case 'upload':
        this.photoUpload();
        break;
      case 'webcam':
        this.photoWebcam();
        break;
      default:
        break;
    }
  }

  action( $sure = false, $what = '',  ) {
    if ( $what != '' ) {
      this.what = $what;
    }

    if ( !$sure ) {
      switch (  +this.list.getValue( this.router.persondata, 'cardStatus', true ) ) {
        case 1:
          this.showWarning( 'warnings.cardrequested' );
          break;
        case 2:
          this.showWarning( 'warnings.cardinproduction' );
          break;
        case 3:
          this.showWarning( 'warnings.cardsend' );
          break;
        default:
          this.doAction();
          break;
      }
    } else {
      this.hideWarning();
      this.doAction();
    }

  }

  cancel() {
    this.hideWarning();
  }

  async photoUpload() {
    let uploadButton = document.createElement('input');
    uploadButton.id="uploadButton";
    uploadButton.type="file";
    uploadButton.style.position = 'fixed';
    uploadButton.style.left = '0';
    uploadButton.style.top = '0';
    uploadButton.style.opacity = '0';
    uploadButton.accept = 'image/*';
    uploadButton.addEventListener('change', async(event) => await this.processFile(event));
    document.body.appendChild(uploadButton);
    uploadButton.click();
  }

  async processFile(event) {

    this.router.toggleshowwait( true );

    let file       = event.target.files[0];
    let fileReader = new FileReader();

    fileReader.onloadend = async (e) => {


      if ( file.type == 'image/heic' || file.type ==  'image/heif'   ) {

        let blob:Blob = file;

        await heic2any({ blob, toType:"image/jpeg", quality: 0 }).then( async (jpgBlob) => {
          this.photo.photo = await this.blobToBase64(jpgBlob);
        });

      } else {
        this.photo.photo = fileReader.result;
      }

      this.photo.webcamPhoto = false;
      this.router.goTo('photo');
    }

    fileReader.readAsDataURL(file);

    document.getElementById("uploadButton").remove();
  }


  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  showPhotoRequirements() {
    this.router.showphotorequirements = true;
  }

  photoWebcam() {
    this.router.goTo('webcam');
  }

  showStatus() {
    this.router.goTo('cardstatus');
  }

  showInfo() {
    this.router.toggleshowwait(true);
    this.router.goTo('cardinfo');
  }

  showPreview() {
    this.router.goTo('cardpreview');
  }

  activateCard() {
    this.router.goTo('activatecard');
  }

  blockCard() {
    this.router.goTo('blockcard');
  }

  deblockCard() {
    this.router.goTo('deblockcard');
  }

  digitalCard() {
    this.router.goTo('digitalcard');
    //window.open( 'digitalcard/'+this.session.loginID );
  }

  nameOnCard() {
    this.router.goTo('nameoncard');
  }

  licensePlate() {
    this.router.goTo('licenseplate');
  }

  chargingcard() {
    this.router.goTo('chargingcard');
  }

  requestCard() {
    this.router.goTo('requestcard');
  }

  photopermissions() {
    this.router.goTo('photopermissions');
  }

  changeDeliveryAddress() {
    this.router.goTo('deliveryaddress');
  }

  logout() {
    this.router.logout();
  }

  ngOnChanges() {
  }

  ngOnDestroy() {


    //this.router.goHome();
  }
}
