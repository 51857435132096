<div *ngIf="router.showwait" id="showwait" class="w3-modal w3-modal-theme w3-show">
  <div id="wait" class="w3-modal-content w3-theme-l4" >
    <div class="w3-display-middle w3-center">
      <fa-icon [icon]="icons.faspinner" [spin]="true" class="w3-jumbo"></fa-icon>
      <p class="w3-small">
        {{ router.waittext | translate: router.transparams }}
      </p>
    </div>
  </div>
</div>
