<div
  *ngIf   = "message.message != '' && !message.title"
  id      = "message"
  class   = "w3-panel w3-padding w3-display-container"
  [class] = "getClass()"
>
  <fa-icon *ngIf="message.showClose" id="icon-close" [icon]="icons.fatimes" class="w3-display-topright w3-hover-shadow close" (click)="close()" title="{{ 'buttons.close' | translate: router.transparams }}"></fa-icon>
  <p class="w3-margin-right" [innerHTML]="message.message | translate: router.transparams"></p>
</div>

<p
  *ngIf       = "message.message != '' && message.title"
  id          = "message"
  [innerHTML] = "message.message | translate: router.transparams"
>
</p>
