<div *ngIf="initialized" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div *ngIf="this.environment.getEnvValue('logintype') != 'surf'" class="w3-margin">
    <input
      type        = "button"
      id          = "ok"
      name        = "ok"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      title       = "{{ 'buttons.ok' | translate: router.transparams }}"
      value       = "{{ 'buttons.ok' | translate: router.transparams }}"
      (click)     = "login()"
    >
  </div>
</div>
