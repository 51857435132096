import { Injectable } from '@angular/core';
import { format, parse, parseISO, isValid } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  datetimeformat  : string = "dd-MM-yyyy HH:mm:ss";
  datetimeformat2 : string = "dd-MM-yyyy HH:mm";
  dateformat      : string = "dd-MM-yyyy";


  constructor() {}

  datetostring( date:string, dateformat:string = this.dateformat, empty:boolean = true ):string {

    if ( typeof date == undefined || date == '' || date == null ) {
      if ( empty ) {
        return '';
      } else {
        return format( new Date(), dateformat );
      }
    }

    let newdate = parseISO( date );

    if ( isValid(newdate) ) {
      return format( newdate, dateformat );
    }

    newdate = parse( date, this.datetimeformat, new Date() );

    if ( isValid(newdate) ) {
      return format( newdate, dateformat );
    }

    return '';
  }

}
