import { Injectable }              from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BarcodeService {
  codes128 =  {
    "32" : 	{"position" : "0" , "ascii" : "32" , "digits" : "11011001100" , "widths" : "212222"} ,
    "33" : 	{"position" : "1" , "ascii" : "33" , "digits" : "11001101100" , "widths" : "222122"} ,
    "34" : 	{"position" : "2" , "ascii" : "34" , "digits" : "11001100110" , "widths" : "222221"} ,
    "35" : 	{"position" : "3" , "ascii" : "35" , "digits" : "10010011000" , "widths" : "121223"} ,
    "36" : 	{"position" : "4" , "ascii" : "36" , "digits" : "10010001100" , "widths" : "121322"} ,
    "37" : 	{"position" : "5" , "ascii" : "37" , "digits" : "10001001100" , "widths" : "131222"} ,
    "38" : 	{"position" : "6" , "ascii" : "38" , "digits" : "10011001000" , "widths" : "122213"} ,
    "39" : 	{"position" : "7" , "ascii" : "39" , "digits" : "10011000100" , "widths" : "122312"} ,
    "40" : 	{"position" : "8" , "ascii" : "40" , "digits" : "10001100100" , "widths" : "132212"} ,
    "41" : 	{"position" : "9" , "ascii" : "41" , "digits" : "11001001000" , "widths" : "221213"} ,
    "42" : 	{"position" : "10" , "ascii" : "42" , "digits" : "11001000100" , "widths" : "221312"} ,
    "43" : 	{"position" : "11" , "ascii" : "43" , "digits" : "11000100100" , "widths" : "231212"} ,
    "44" : 	{"position" : "12" , "ascii" : "44" , "digits" : "10110011100" , "widths" : "112232"} ,
    "45" : 	{"position" : "13" , "ascii" : "45" , "digits" : "10011011100" , "widths" : "122132"} ,
    "46" : 	{"position" : "14" , "ascii" : "46" , "digits" : "10011001110" , "widths" : "122231"} ,
    "47" : 	{"position" : "15" , "ascii" : "47" , "digits" : "10111001100" , "widths" : "113222"} ,
    "48" : 	{"position" : "16" , "ascii" : "48" , "digits" : "10011101100" , "widths" : "123122"} ,
    "49" : 	{"position" : "17" , "ascii" : "49" , "digits" : "10011100110" , "widths" : "123221"} ,
    "50" : 	{"position" : "18" , "ascii" : "50" , "digits" : "11001110010" , "widths" : "223211"} ,
    "51" : 	{"position" : "19" , "ascii" : "51" , "digits" : "11001011100" , "widths" : "221132"} ,
    "52" : 	{"position" : "20" , "ascii" : "52" , "digits" : "11001001110" , "widths" : "221231"} ,
    "53" : 	{"position" : "21" , "ascii" : "53" , "digits" : "11011100100" , "widths" : "213212"} ,
    "54" : 	{"position" : "22" , "ascii" : "54" , "digits" : "11001110100" , "widths" : "223112"} ,
    "55" : 	{"position" : "23" , "ascii" : "55" , "digits" : "11101101110" , "widths" : "312131"} ,
    "56" : 	{"position" : "24" , "ascii" : "56" , "digits" : "11101001100" , "widths" : "311222"} ,
    "57" : 	{"position" : "25" , "ascii" : "57" , "digits" : "11100101100" , "widths" : "321122"} ,
    "58" : 	{"position" : "26" , "ascii" : "58" , "digits" : "11100100110" , "widths" : "321221"} ,
    "59" : 	{"position" : "27" , "ascii" : "59" , "digits" : "11101100100" , "widths" : "312212"} ,
    "60" : 	{"position" : "28" , "ascii" : "60" , "digits" : "11100110100" , "widths" : "322112"} ,
    "61" : 	{"position" : "29" , "ascii" : "61" , "digits" : "11100110010" , "widths" : "322211"} ,
    "62" : 	{"position" : "30" , "ascii" : "62" , "digits" : "11011011000" , "widths" : "212123"} ,
    "63" : 	{"position" : "31" , "ascii" : "63" , "digits" : "11011000110" , "widths" : "212321"} ,
    "64" : 	{"position" : "32" , "ascii" : "64" , "digits" : "11000110110" , "widths" : "232121"} ,
    "65" : 	{"position" : "33" , "ascii" : "65" , "digits" : "10100011000" , "widths" : "111323"} ,
    "66" : 	{"position" : "34" , "ascii" : "66" , "digits" : "10001011000" , "widths" : "131123"} ,
    "67" : 	{"position" : "35" , "ascii" : "67" , "digits" : "10001000110" , "widths" : "131321"} ,
    "68" : 	{"position" : "36" , "ascii" : "68" , "digits" : "10110001000" , "widths" : "112313"} ,
    "69" : 	{"position" : "37" , "ascii" : "69" , "digits" : "10001101000" , "widths" : "132113"} ,
    "70" : 	{"position" : "38" , "ascii" : "70" , "digits" : "10001100010" , "widths" : "132311"} ,
    "71" : 	{"position" : "39" , "ascii" : "71" , "digits" : "11010001000" , "widths" : "211313"} ,
    "72" : 	{"position" : "40" , "ascii" : "72" , "digits" : "11000101000" , "widths" : "231113"} ,
    "73" : 	{"position" : "41" , "ascii" : "73" , "digits" : "11000100010" , "widths" : "231311"} ,
    "74" : 	{"position" : "42" , "ascii" : "74" , "digits" : "10110111000" , "widths" : "112133"} ,
    "75" : 	{"position" : "43" , "ascii" : "75" , "digits" : "10110001110" , "widths" : "112331"} ,
    "76" : 	{"position" : "44" , "ascii" : "76" , "digits" : "10001101110" , "widths" : "132131"} ,
    "77" : 	{"position" : "45" , "ascii" : "77" , "digits" : "10111011000" , "widths" : "113123"} ,
    "78" : 	{"position" : "46" , "ascii" : "78" , "digits" : "10111000110" , "widths" : "113321"} ,
    "79" : 	{"position" : "47" , "ascii" : "79" , "digits" : "10001110110" , "widths" : "133121"} ,
    "80" : 	{"position" : "48" , "ascii" : "80" , "digits" : "11101110110" , "widths" : "313121"} ,
    "81" : 	{"position" : "49" , "ascii" : "81" , "digits" : "11010001110" , "widths" : "211331"} ,
    "82" : 	{"position" : "50" , "ascii" : "82" , "digits" : "11000101110" , "widths" : "231131"} ,
    "83" : 	{"position" : "51" , "ascii" : "83" , "digits" : "11011101000" , "widths" : "213113"} ,
    "84" : 	{"position" : "52" , "ascii" : "84" , "digits" : "11011100010" , "widths" : "213311"} ,
    "85" : 	{"position" : "53" , "ascii" : "85" , "digits" : "11011101110" , "widths" : "213131"} ,
    "86" : 	{"position" : "54" , "ascii" : "86" , "digits" : "11101011000" , "widths" : "311123"} ,
    "87" : 	{"position" : "55" , "ascii" : "87" , "digits" : "11101000110" , "widths" : "311321"} ,
    "88" : 	{"position" : "56" , "ascii" : "88" , "digits" : "11100010110" , "widths" : "331121"} ,
    "89" : 	{"position" : "57" , "ascii" : "89" , "digits" : "11101101000" , "widths" : "312113"} ,
    "90" : 	{"position" : "58" , "ascii" : "90" , "digits" : "11101100010" , "widths" : "312311"} ,
    "91" : 	{"position" : "59" , "ascii" : "91" , "digits" : "11100011010" , "widths" : "332111"} ,
    "92" : 	{"position" : "60" , "ascii" : "92" , "digits" : "11101111010" , "widths" : "314111"} ,
    "93" : 	{"position" : "61" , "ascii" : "93" , "digits" : "11001000010" , "widths" : "221411"} ,
    "94" : 	{"position" : "62" , "ascii" : "94" , "digits" : "11110001010" , "widths" : "431111"} ,
    "95" : 	{"position" : "63" , "ascii" : "95" , "digits" : "10100110000" , "widths" : "111224"} ,
    "96" : 	{"position" : "64" , "ascii" : "96" , "digits" : "10100001100" , "widths" : "111422"} ,
    "97" : 	{"position" : "65" , "ascii" : "97" , "digits" : "10010110000" , "widths" : "121124"} ,
    "98" : 	{"position" : "66" , "ascii" : "98" , "digits" : "10010000110" , "widths" : "121421"} ,
    "99" : 	{"position" : "67" , "ascii" : "99" , "digits" : "10000101100" , "widths" : "141122"} ,
    "100" : 	{"position" : "68" , "ascii" : "100" , "digits" : "10000100110" , "widths" : "141221"} ,
    "101" : 	{"position" : "69" , "ascii" : "101" , "digits" : "10110010000" , "widths" : "112214"} ,
    "102" : 	{"position" : "70" , "ascii" : "102" , "digits" : "10110000100" , "widths" : "112412"} ,
    "103" : 	{"position" : "71" , "ascii" : "103" , "digits" : "10011010000" , "widths" : "122114"} ,
    "104" : 	{"position" : "72" , "ascii" : "104" , "digits" : "10011000010" , "widths" : "122411"} ,
    "105" : 	{"position" : "73" , "ascii" : "105" , "digits" : "10000110100" , "widths" : "142112"} ,
    "106" : 	{"position" : "74" , "ascii" : "106" , "digits" : "10000110010" , "widths" : "142211"} ,
    "107" : 	{"position" : "75" , "ascii" : "107" , "digits" : "11000010010" , "widths" : "241211"} ,
    "108" : 	{"position" : "76" , "ascii" : "108" , "digits" : "11001010000" , "widths" : "221114"} ,
    "109" : 	{"position" : "77" , "ascii" : "109" , "digits" : "11110111010" , "widths" : "413111"} ,
    "110" : 	{"position" : "78" , "ascii" : "110" , "digits" : "11000010100" , "widths" : "241112"} ,
    "111" : 	{"position" : "79" , "ascii" : "111" , "digits" : "10001111010" , "widths" : "134111"} ,
    "112" : 	{"position" : "80" , "ascii" : "112" , "digits" : "10100111100" , "widths" : "111242"} ,
    "113" : 	{"position" : "81" , "ascii" : "113" , "digits" : "10010111100" , "widths" : "121142"} ,
    "114" : 	{"position" : "82" , "ascii" : "114" , "digits" : "10010011110" , "widths" : "121241"} ,
    "115" : 	{"position" : "83" , "ascii" : "115" , "digits" : "10111100100" , "widths" : "114212"} ,
    "116" : 	{"position" : "84" , "ascii" : "116" , "digits" : "10011110100" , "widths" : "124112"} ,
    "117" : 	{"position" : "85" , "ascii" : "117" , "digits" : "10011110010" , "widths" : "124211"} ,
    "118" : 	{"position" : "86" , "ascii" : "118" , "digits" : "11110100100" , "widths" : "411212"} ,
    "119" : 	{"position" : "87" , "ascii" : "119" , "digits" : "11110010100" , "widths" : "421112"} ,
    "120" : 	{"position" : "88" , "ascii" : "120" , "digits" : "11110010010" , "widths" : "421211"} ,
    "121" : 	{"position" : "89" , "ascii" : "121" , "digits" : "11011011110" , "widths" : "212141"} ,
    "122" : 	{"position" : "90" , "ascii" : "122" , "digits" : "11011110110" , "widths" : "214121"} ,
    "123" : 	{"position" : "91" , "ascii" : "123" , "digits" : "11110110110" , "widths" : "412121"} ,
    "124" : 	{"position" : "92" , "ascii" : "124" , "digits" : "10101111000" , "widths" : "111143"} ,
    "125" : 	{"position" : "93" , "ascii" : "125" , "digits" : "10100011110" , "widths" : "111341"} ,
    "126" : 	{"position" : "94" , "ascii" : "126" , "digits" : "10001011110" , "widths" : "131141"} ,
    "195" : 	{"position" : "95" , "ascii" : "195" , "digits" : "10111101000" , "widths" : "114113"} ,
    "196" : 	{"position" : "96" , "ascii" : "196" , "digits" : "10111100010" , "widths" : "114311"} ,
    "197" : 	{"position" : "97" , "ascii" : "197" , "digits" : "11110101000" , "widths" : "411113"} ,
    "198" : 	{"position" : "98" , "ascii" : "198" , "digits" : "11110100010" , "widths" : "411311"} ,
    "199" : 	{"position" : "99" , "ascii" : "199" , "digits" : "10111011110" , "widths" : "113141"} ,
    "200" : 	{"position" : "100" , "ascii" : "200" , "digits" : "10111101110" , "widths" : "114131"} ,
    "201" : 	{"position" : "101" , "ascii" : "201" , "digits" : "11101011110" , "widths" : "311141"} ,
    "202" : 	{"position" : "102" , "ascii" : "202" , "digits" : "11110101110" , "widths" : "411131"} ,
    "starta" : 	{"position" : "103" , "ascii" : "203" , "digits" : "11010000100" , "widths" : "211412"} ,
    "startb" : 	{"position" : "104" , "ascii" : "204" , "digits" : "11010010000" , "widths" : "211214"} ,
    "startc" : 	{"position" : "105" , "ascii" : "205" , "digits" : "11010011100" , "widths" : "211232"} ,
    "stop" :	{"position" : "106" , "ascii" : "stop" , "digits" : "11000111010" , "widths" : "233111"} ,
    "revstop" :	{"position" : "107" , "ascii" : "revstop" , "digits" : "11010111000" , "widths" : "211133"} ,
    "stoppattern" : 	{"position" : "108" , "ascii" : "206" , "digits" : "1100011101011" , "widths" : "2331112" }
  	};

  constructor ( ) {}

  getcode128 ( $text: string = '' ) {
    let codes    = [ this.codes128['starta']['widths'] ];
    let checksum = +this.codes128['starta']['ascii'];

    for ( let i = 0; i < $text.length; i++ ) {
      codes.push(this.codes128[$text.charCodeAt(i)]['widths']);
      checksum += +this.codes128[$text.charCodeAt(i)]['ascii'];
    }

    let newchecksum = checksum%103;

    for ( let i in this.codes128 ) {
      if ( this.codes128[i]['position'] == newchecksum ) {
        codes.push(this.codes128[i]['widths']);
      }
    }

    codes.push(this.codes128['stoppattern']['widths']);

    return codes.join('').split('');
  }
}
