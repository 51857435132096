import { Injectable }         from '@angular/core';
import { DataService }        from '@omnilib/services/data.service';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { ListService }        from '@omnilib/services/list.service';
import { MessageService }     from '@omnipas/services/message.service';
import { RouterService }      from '@omnipas/services/router.service';

@Injectable({
  providedIn: 'root',
})
export class PersonService {

  constructor ( public data:        DataService
              , public environment: EnvironmentService
              , public list:        ListService
              , public message:     MessageService
              , public router:      RouterService
              ) {}

  async getPersonData() {
    if ( this.data.getjwt() != '' ) {

      let params = {};
      let result = await this.data.callapi ( 'get', 'person', params );

      if ( result['result']['code'] == '0' && !this.list.emptyList(result['result']['resultset']) ) {
        this.router.persondata = result['result']['resultset'];

        this.environment.profile = this.list.getValue( this.router.persondata, 'cardholderProfile', true ).toLowerCase();

        if ( this.environment.getEnvValue('firstnameonwelcome') ) {
          this.router.transparams.firstname = this.list.getValue( this.router.persondata,'firstName',true );
        }

        this.router.transparams.fullname  = this.list.getValue(this.router.persondata, 'firstName', true) != '' ? this.list.getValue(this.router.persondata, 'firstName', true) : this.list.getValue(this.router.persondata, 'initials', true);
        this.router.transparams.fullname += this.list.getValue(this.router.persondata, 'middleName', true) != '' ? ' ' + this.list.getValue(this.router.persondata, 'middleName', true) : '';
        this.router.transparams.fullname += ' ' + this.list.getValue(this.router.persondata, 'lastName', true);

        this.router.amount = await this.environment.getEnvValue( 'cardprice' )
                                ?? this.list.getValue( this.environment.getEnvValue( 'cardprices' ), await this.list.getValue(this.router.persondata, 'cardholderProfile', true).toString(), true )
                                ?? this.list.getValue( this.environment.getEnvValue( 'cardprices' ), 'default', true )
                                ?? '0000'
                           ;

        this.router.transparams.amount = this.formatAmount( this.router.amount );

        await this.getfreefields();

        if ( this.environment.getEnvValue( 'cardhist' ) ) {
          await this.getcardhist();
        }

        return true;
      } else {
        this.message.setErrorMessage ( 'erors.unknownError', false, true );
      }
    }
    return false;
  }

  async getcardhist() {

    let result = {};

    result = await this.data.callapi ( 'get', 'cardhist', {} );

    if ( result['result']['code'] && result['result']['code'] == '0' && !this.list.emptyList( result['result']['resultset'] ) ) {
      this.router.cardhist =  result['result']['resultset'];

    }
  }

  async getfreefields() {
    let result = {};

    try {
      result = await this.data.callapi ( 'get', 'freefield/person', {} );

      if ( result['result']['code'] && result['result']['code'] == '0' && !this.list.emptyList( result['result']['resultset'] ) ) {
        this.router.freefields['personfreefields'] =  result['result']['resultset'];
      }
    } catch(e) {
      //nothing
    }

    try {
      result = await this.data.callapi ( 'get', 'freefield/cardholder', {} );

      if ( result['result']['code'] && result['result']['code'] == '0' && !this.list.emptyList( result['result']['resultset'] ) ) {
        this.router.freefields['cardholderfreefields'] =  result['result']['resultset'];
      }
    } catch(e) {
      //nothing
    }
  }

  formatAmount($amount) {
    if ($amount == 0) {
      return '€ 0,00';
    } else {
      return (
        '€ ' +
        $amount.toString().substring(0, $amount.toString().length - 2) +
        ',' +
        $amount.toString().substring($amount.toString().length - 2)
      );
    }
  }
}
