import { Injectable }         from '@angular/core';
import { map, catchError }    from 'rxjs/operators';
import { DataService }        from '@omnilib/services/data.service';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { LanguageService }    from '@omnipas/services/language.service';
import { PersonService }      from '@omnipas/services/person.service';
import { RouterService }      from '@omnipas/services/router.service';
import { Title }              from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor ( public data:        DataService
              , public environment: EnvironmentService
              , public language:    LanguageService
              , public person:      PersonService
              , public router:      RouterService
              , public title:       Title
              ) {}

  async load( jwt: string = '' ) {
    this.router.toggleshowwait( true );
    this.language.initialize();

    if ( jwt != '' ) {
      await this.data.setjwt( jwt );
    }

    if ( this.data.getjwt() != '' ) {
      await this.person.getPersonData();
    }

    this.router.transparams.product            = this.environment.getEnvValue('product');
    this.router.transparams.customer_fullname  = this.environment.getEnvValue('customer_fullname');
    this.router.transparams.customer_shortname = this.environment.getEnvValue('customer_shortname');
    this.router.transparams.url                = this.environment.getEnvValue('url');

    if ( this.router.transparams.product != '' ) {
      this.title.setTitle( this.router.transparams.product );
    }

    this.router.toggleshowwait( false );
  }
}
