import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService }             from '@omnilib/services/data.service';
import { DeliveryAddressService }  from '@omnipas/services/deliveryaddress.service';
import { ListService }             from '@omnilib/services/list.service';
import { LoadingService }          from '@omnipas/services/loading.service';
import { MessageService }          from '@omnipas/services/message.service';
import { PageHeaderService }       from '@omnipas/services/pageheader.service';
import { RouterService }           from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-deliveryaddress',
  templateUrl: './deliveryaddress.component.html'
})
export class DeliveryAddressComponent implements OnInit, OnDestroy {

  initialized:       boolean = false;

  postalcode:        string = '';
  housenumber:       string = '';
  housenumbersuffix: string = '';
  street:            string = '';
  city:              string = '';

  change:            boolean = false;
  addressfound:      boolean = false;
  saved:             boolean = false;
  searching:         boolean = false;

  addressline1:      string = '';
  addressline2:      string = '';
  addresstype:       string = '';


  constructor ( public data:            DataService
              , public deliveryaddress: DeliveryAddressService
              , public list:            ListService
              , public loading:         LoadingService
              , public message:         MessageService
              , public pageheader:      PageHeaderService
              , public router:          RouterService
              ) {
  }

  async ngOnInit() {

    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader( 'pagetitle.deliveryaddress', 'pagetext.deliveryaddress' );
    }

    await this.deliveryaddress.get();

    this.initialized = true;

    this.router.toggleshowwait( false );
  }

  logout() {
    this.router.logout();
  }

  toggleChange() {
    this.pageheader.setPageHeader( 'pagetitle.deliveryaddress', 'deliveryaddress.line5' );
    this.change = true;
  }

  formatZipCode() {
    this.postalcode = this.postalcode.toUpperCase();

    if ( this.postalcode.length == 4 ) {
      this.postalcode = this.postalcode + ' ';
      return;
    }

    if ( this.postalcode.length == 6 ) {
      if ( this.postalcode.charAt(4) != ' ' ) {
        this.postalcode = this.postalcode.substring(0,4) + ' ' + this.postalcode.substring(4);
      }
    }

    if ( this.postalcode.length != 7 || this.housenumber.length == 0  ) {
      this.street       = '';
      this.city         = '';
      this.addressfound = false;
      return;
    }
  }

  async searchAddress() {
    if ( this.postalcode.length != 7 || this.housenumber.length == 0  ) {
      this.street       = '';
      this.city         = '';
      this.addressfound = false;
      this.searching    = false;
      return;
    }

    this.router.toggleshowwait( true );

    this.searching = true;

    let result = await this.data.getpc( this.postalcode.replace(' ', ''), this.housenumber );

    if ( result != null && result['result']['code'] && result['result']['code'] == '0' && !this.list.emptyList( result['result']['resultset'] ) ) {
      this.street       = this.list.getValue( result['result']['resultset'], 'street', true );
      this.city         = this.list.getValue ( result['result']['resultset'], 'city', true );
      this.addressfound = true;
    } else  {
      this.addressfound = false;
    }

    this.searching = false;
    this.router.toggleshowwait( false );
  }

  async submit() {
    this.router.toggleshowwait( true );

    this.deliveryaddress.addressLine1 = ( this.street + ' ' + this.housenumber + ' ' + this.housenumbersuffix ).trim();
    this.deliveryaddress.addressLine2 = this.postalcode + '  ' + this.city;

    if ( await this.deliveryaddress.update() ) {
      this.message.setSuccessMessage('deliveryaddress.success');
      this.saved = true;
    } else {
      this.message.setErrorMessage('deliveryaddress.failure');
    }

    this.router.toggleshowwait( false );
  }

  back() {
    this.postalcode        = '';
    this.housenumber       = '';
    this.housenumbersuffix = '';
    this.street            = '';
    this.city              = '';

    this.change            = false;
    this.addressfound      = false;
    this.saved             = false;
    this.searching         = false;
    this.message.error     = false;
  }

  cancel() {
    this.router.goHome();
  }


  ngOnDestroy() {
    return;
  }

}
