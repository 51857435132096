<div class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div *ngIf="!message.success && !message.error" class="w3-margin">
    <div class="w3-margin-top">
      <div *ngIf="vehicledata.licenseplate != ''" id="current" class="w3-margin-top">{{ 'licenseplate.current' | translate: router.transparams }}: {{ vehicledata.countrycode }} {{ vehicledata.licenseplate }}</div>
      <div *ngIf="vehicledata.licenseplate == ''" id="current" class="w3-margin-top">{{ 'licenseplate.unknown' | translate: router.transparams }}</div>
    </div>
    <div class="w3-margin-top w3-margin-bottom">
      <label for="new">{{ 'licenseplate.new' | translate: router.transparams }}:</label>
      <div id="new" class="w3-margin-top">
        <select
          id    = "countrycode"
          class = "w3-select w3-input-theme w3-border w3-round w3-margin-right"
          [(ngModel)] = "vehicledata.countrycode"
        >
          <option value="A"  >A  </option>
          <option value="B"  >B  </option>
          <option value="BG" >BG </option>
          <option value="CH" >CH </option>
          <option value="CZ" >CZ </option>
          <option value="D"  >D  </option>
          <option value="DK" >DK </option>
          <option value="E"  >E  </option>
          <option value="F"  >F  </option>
          <option value="GB" >GB </option>
          <option value="H"  >H  </option>
          <option value="I"  >I  </option>
          <option value="IRL">IRL</option>
          <option value="L"  >L  </option>
          <option value="LT" >LT </option>
          <option value="LV" >LV </option>
          <option value="NL" >NL </option>
          <option value="N"  >N  </option>
          <option value="P"  >P  </option>
          <option value="PL" >PL </option>
          <option value="RO" >RO </option>
          <option value="S"  >S  </option>
          <option value="SK" >SK </option>
        </select>
        <input
          id            = "licenseplate"
          name          = "licenseplate"
          class         = "w3-input w3-border w3-input-theme w3-round"
          type          = "text"
          placeholder   = "{{ 'labels.licenseplate' | translate: router.transparams }}"
          autocomplete  = "licenseplate"
          [(ngModel)]   = "vehicledata.licenseplate"
          required      = "required"
          (keyup)       = "keyup()"
        >
      </div>
    </div>
    <div class="w3-margin-top w3-margin-bottom">
      <input
        type        = "button"
        class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round w3-margin-right"
        name        = "cancel"
        value       = "{{ 'buttons.back' | translate: router.transparams }}"
        title       = "{{ 'buttons.back' | translate: router.transparams }}"
        (click)     = "cancel()"
      >
      <input
        type        = "button"
        class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
        name        = "submit"
        value       = "{{ 'buttons.save' | translate: router.transparams }}"
        title       = "{{ 'buttons.save' | translate: router.transparams }}"
        (click)     = "submit()"
      >
    </div>
  </div>
  <div *ngIf="message.success || message.error" class="w3-margin">
    <p>{{ 'requestcard.logout' | translate: router.transparams }}</p>
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "logout()"
    >
  </div>
</div>
