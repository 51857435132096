import { Component, OnInit, OnDestroy } from '@angular/core';
import { IconsService }      from '@omnipas/services/icons.service';
import { RouterService }     from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-showwait',
  templateUrl: './showwait.component.html'
})
export class ShowwaitComponent implements OnInit, OnDestroy {

  params;

  constructor ( public icons:   IconsService
              , public router:  RouterService
              ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    return;
  }

}
