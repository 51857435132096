import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { IconsService }       from '@omnipas/services/icons.service';
import { LanguageService }    from '@omnipas/services/language.service';
import { ListService }        from '@omnilib/services/list.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { MessageService }     from '@omnipas/services/message.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { RouterService }      from '@omnipas/services/router.service';
import { format, parse  }     from 'date-fns';

@Component({
  selector: 'omnipas-digitalcard',
  templateUrl: './digitalcard.component.html'
})
export class DigitalCardComponent implements OnInit, OnDestroy {

  initialized: boolean = false;
  invalidLink: boolean = false;
  showwhat:    string  = 'pass';

  blockwidth:  string  = '0px';
  blockheight: string  = '0px';

  expdate:     string  = '';
  startdate:   string  = '';

  constructor ( public environment: EnvironmentService
              , public icons:       IconsService
              , public language:    LanguageService
              , public list:        ListService
              , public loading:     LoadingService
              , public message:     MessageService
              , public pageheader:  PageHeaderService
              , public router:      RouterService
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    await this.initialize();
    this.router.toggleshowwait( false );
  }

  async initialize() {
    await this.loading.load();

    if ( this.list.emptyList( this.router.persondata ) ) {
      this.message.setErrorMessage( 'digitalcard.error.noactivecard' );
      return;
    }

    try {
      this.expdate = format( parse( this.list.getValue( this.router.persondata, 'cardEndDate', true ), "dd-MM-yyyy HH:mm:ss", new Date() ), "dd-MM-yyyy" );
    } catch(e) {
      this.expdate = '';
    }

    try {
      this.startdate = format( parse( this.list.getValue( this.router.persondata, 'cardStartDate', true ), "dd-MM-yyyy HH:mm:ss", new Date() ), "dd-MM-yyyy" );
    } catch(e) {
      this.startdate = format( new Date(), "dd-MM-yyyy");
    }

    this.pageheader.setPageHeader( '', '' );

    this.blockwidth  = ( Math.min ( this.router.mainwidth, this.router.mainheight ) / 2 ) + 'px';
    this.blockheight = ( Math.min ( this.router.mainwidth, this.router.mainheight ) / 4  ) + 'px';

    this.router.digitalcardinnewtab = ( window.innerWidth > window.innerHeight && window.innerWidth > 900 );

    this.router.showdigitalcard = true;
    this.initialized = true;
  }

  close() {
    this.router.goHome();
  }

  ngOnDestroy() {
    this.router.goHome();
  }

  getClass() {
     let classList = this.list.getValue( this.router.persondata, 'cardholderProfile', true ).toLowerCase();
     classList += this.environment.getEnvValue('langimage') ? ' ' + this.language.currentLanguage : '';
     return classList;
  }

  getorientation() {
    return ( window.innerWidth > window.innerHeight ? 'landscape' : 'portrait' ) + ( window.innerWidth < 900 || window.innerHeight < 900 ? ' mobile' : ' desktop' );
  }

}
