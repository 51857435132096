<div *ngIf="initialized" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>

  <div *ngIf="result == 'start' && !message.success && !message.error" class="w3-margin">
    <form #ingenicoForm id="ingenicoForm" name="ingenicoForm" action="{{ payment.url }}" method="post">
      <p>{{ 'payment.sure' | translate: router.transparams }}</p>
      <input *ngFor="let input of payment.ingenico | keyvalue" type="hidden" name="{{ input.key }}" value="{{ input.value }}">
    </form>
    <input
      type        = "submit"
      class       = "w3-button w3-border w3-button-theme w3-mobile w3-margin-right pageButton w3-round"
      name        = "submit"
      value       = "{{ 'buttons.yes' | translate: router.transparams }}"
      title       = "{{ 'buttons.yes' | translate: router.transparams }}"
      (click)     = "submit()"
    >
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "cancel"
      value       = "{{ 'buttons.no' | translate: router.transparams }}"
      title       = "{{ 'buttons.no' | translate: router.transparams }}"
      (click)     = "cancel()"
    >
  </div>
  <div *ngIf="showLogout" class="w3-margin">
    <p>{{ 'payment.logout' | translate: router.transparams }}</p>
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "logout()"
    >
  </div>
</div>
