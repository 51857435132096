import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'customTitleCase'})
export class CustomTitleCasePipe implements PipeTransform {
  transform( value: string ): string {
    return value == '' ? value : value.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));;
  }
}

@Pipe({name: 'customInitCap'})
export class CustomInitCapPipe implements PipeTransform {
  transform( value: string ): string {
    return value == '' ? value : value[0].toUpperCase() + value.slice(1);
  }
}
