import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { RouterService }     from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-pageheader',
  templateUrl: './pageheader.component.html'
})
export class PageHeaderComponent implements OnInit, OnDestroy {

  constructor ( public pageheader: PageHeaderService
              , public router:     RouterService
              ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    return;
  }

}
