<div *ngIf="initialized" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div *ngIf="!change && !saved" class="w3-margin">
    @if( deliveryaddress.addresstype == 'deliveryaddress' || deliveryaddress.addresstype == 'systemaddress' ) {
      <p>{{ 'deliveryaddress.line1' | translate: router.transparams }}</p>
      <p>
        <b>
          <span>{{ deliveryaddress.addressLine1 }}</span>
          <br/>
          <span>{{ deliveryaddress.addressLine2 }}</span>
        </b>
      </p>
      <p>{{ 'deliveryaddress.line2' | translate: router.transparams }}</p>
      <p>{{ 'deliveryaddress.line3' | translate: router.transparams }}</p>
      <p>{{ 'deliveryaddress.line4' | translate: router.transparams }}</p>
    } @else {
      <p>{{ 'deliveryaddress.noaddress' | translate: router.transparams }}</p>
    }
    <input
      type        = "button"
      id          = "agree"
      name        = "agree"
      class       = "w3-button w3-border w3-button-theme w3-margin-right pageButton w3-round"
      title       = "{{ 'buttons.agree' | translate: router.transparams }}"
      value       = "{{ 'buttons.agree' | translate: router.transparams }}"
      (click)     = "logout()"
      tabindex    = "1"
    >
    <input
      type        = "button"
      id          = "change"
      name        = "change"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      title       = "{{ 'buttons.changeaddress' | translate: router.transparams }}"
      value       = "{{ 'buttons.changeaddress' | translate: router.transparams }}"
      (click)     = "toggleChange()"
      tabindex    = "2"
    >
  </div>
  <div *ngIf="change && !saved" #deliveryAddress id="deliveryAddress" class="w3-margin">
    <label for="postalcode">{{ 'labels.postalcode' | translate: router.transparams }}</label>
    <input
      id              = "postalcode"
      name            = "postalcode"
      class           = "w3-input w3-border w3-input-theme w3-round"
      type            = "text"
      placeholder     = "9999 AA"
      pattern         = "[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}"
      [(ngModel)]     = "postalcode"
      (change)        = "formatZipCode(); searchAddress();"
      required        = "required"
      tabindex        = "1"
    >
    <label for="housenumber">{{ 'labels.housenumber' | translate: router.transparams }}</label>
    <input
      id              = "housenumber"
      name            = "housenumber"
      class           = "w3-input w3-border w3-input-theme w3-round"
      type            = "text"
      placeholder     = "99999"
      pattern         = "[1-9][0-9]{4}"
      [(ngModel)]     = "housenumber"
      (keyup.enter)   = "searchAddress()"
      (blur)          = "searchAddress()"
      (change)        = "searchAddress()"
      required        = "required"
      tabindex        = "2"
    >
    <label for="housenumbersuffix">{{ 'labels.housenumbersuffix' | translate: router.transparams }}</label>
    <input
      id            = "housenumbersuffix"
      name          = "housenumbersuffix"
      class         = "w3-input w3-border w3-input-theme w3-round"
      type          = "text"
      placeholder   = ""
      pattern       = ""
      [(ngModel)]   = "housenumbersuffix"
      tabindex      = "3"
    >

    <p *ngIf="!searching && !addressfound">{{ 'deliveryaddress.line6' | translate: router.transparams }}</p>
    <p *ngIf="searching">{{ 'deliveryaddress.searching' | translate: router.transparams }}</p>
    <p *ngIf="addressfound">{{ 'deliveryaddress.line7' | translate: router.transparams }}</p>
    <p *ngIf="addressfound">
      <b>{{ street }} {{ housenumber }} {{ housenumbersuffix }}<br/>{{ postalcode }}&nbsp; &nbsp;{{ city }}</b>
    </p>
    <p *ngIf="addressfound">{{ 'deliveryaddress.line8' | translate: router.transparams }}</p>
    <input
      *ngIf       = 'addressfound'
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "logout()"
      [disabled]  = "addressfound ? '' : 'disabled'"
    >
    <input
      *ngIf       = 'addressfound'
      type        = "submit"
      class       = "w3-button w3-border w3-button-theme w3-mobile w3-margin-right pageButton w3-round"
      name        = "submit"
      value       = "{{ 'buttons.save' | translate: router.transparams }}"
      title       = "{{ 'buttons.save' | translate: router.transparams }}"
      (click)     = "submit()"
      [disabled]  = "addressfound ? '' : 'disabled'"
    >
  </div>
  <div *ngIf="saved" #result id="result" class="w3-margin">
    <p>{{ 'deliveryaddress.line9' | translate: router.transparams }}</p>
    <p>
      <b>{{ street }} {{ housenumber }} {{ housenumbersuffix }}<br/>{{ postalcode }} {{ city }}</b>
    </p>
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "logout()"
    >
  </div>
  <div *ngIf="message.error">
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "back"
      value       = "{{ 'buttons.back' | translate: router.transparams }}"
      title       = "{{ 'buttons.back' | translate: router.transparams }}"
      (click)     = "back()"
    >
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "cancel()"
    >
  </div>
</div>
