<div *ngIf="initialized" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div class="w3-margin">
    <progress value="{{status}}" max="6">{{status}}</progress>
    <p>{{ 'status.description.'+status | translate: router.transparams }}</p>
  </div>
  <div>
    <ul *ngIf="!showList" class="w3-ul">
      <li (click)="toggleShowList()"><h3>{{ 'status.showprogress' | translate: router.transparams }}</h3></li>
    </ul>
    <ul *ngIf="showList" class="w3-ul">
      <li (click)="toggleShowList()"><h3>{{ 'status.hideprogress' | translate: router.transparams }}</h3></li>
      <li [class]="getClass(1)">{{ 'status.description.1' | translate: router.transparams }}</li>
      <li [class]="getClass(2)">{{ 'status.description.2' | translate: router.transparams }}</li>
      <li [class]="getClass(3)">{{ 'status.description.3' | translate: router.transparams }}</li>
      <li [class]="getClass(4)">{{ 'status.description.4' | translate: router.transparams }}</li>
      <li [class]="getClass(5)">{{ 'status.description.5' | translate: router.transparams }}</li>
      <li [class]="getClass(6)">{{ 'status.description.6' | translate: router.transparams }}</li>
    </ul>
  </div>
  <div class="w3-margin">
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile w3-margin-right pageButton w3-round"
      name        = "back"
      value       = "{{ 'buttons.back' | translate: router.transparams }}"
      title       = "{{ 'buttons.back' | translate: router.transparams }}"
      (click)     = "back()"
    >
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "logout()"
    >
  </div>
</div>
